import React, { useEffect } from 'react';

import { OxPageHelmet } from 'src/components/OxPageHelmet';
import { OxBookingSimple } from 'src/components/OxBookingSimple';
import { fireEvent } from 'src/helpers/TagManager';

export default function AppointmentPage({ location }): JSX.Element {
    useEffect(() => {
        fireEvent({ event: 'appointmentFormPageView' });
    }, []);

    return (
        <>
            <OxPageHelmet
                title="Book Now | Holistic Aesthetics | Ouronyx | Dubai | London"
                description="Book a consultation at our London or Dubai Clinics with our leading doctors. Start your Ouronyx journey toward a fresh and more natural looking you today."
                keywords="consultation, aesthetic doctors, St. James, London, clinic"
                image="/images/meta-image-journey.png"
            />
            <OxBookingSimple loginAutoSubmit={location.state?.loginAutoSubmit} />
        </>
    );
}
