import React, { useContext, useEffect } from 'react';
import { TStepProps } from 'src/components/OxBookingSimple/components/OxBookingSimpleDetails/OxBookingSimpleDetails';
import { OxBackButton } from 'src/components/OxBackButton';
import { OxContainer } from 'src/components/OxContainer';
import { OxBookingSimplePaymentForm } from 'src/components/OxBookingSimple/components/OxBookingSimpleDetails/components/OxBookingSimplePaymentForm';
import { Elements } from '@stripe/react-stripe-js';
import { initPayment, userGetUser } from 'src/services/api';
import { BookingFlowContext } from 'src/context/BookingFlowContext';
import { EBookingFlowAction } from 'src/components/OxBooking/OxBookingFlowReducer';
import { getStripePromiseInstance } from 'src/services/stripe';
import * as Styled from 'src/components/OxBookingSimple/components/OxBookingSimpleDetails/OxBookingSimpleDetails.styled';
import { TUser } from 'src/services/api/api.types';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import { WebsiteDataContext } from 'src/context/WebsiteDataContext';
import { CONFIG_PATH } from 'src/services/api/config';

let initialising = false;
let unmounted = false;

const intervalTimeout = 500;
let interval: number;

export const OxBookingSimpleStep2: React.FC<TStepProps> = ({
    onBack,
    active
}: TStepProps): JSX.Element => {
    const { validateResponse } = useValidateResponse();
    const { getConfigValue } = useContext(WebsiteDataContext);
    const { dispatch, state } = useContext(BookingFlowContext);
    const stripePK = getConfigValue(CONFIG_PATH.STRIPE_PUBLISHABLE_KEY, state.clinic?.id);
    const stripePromise = active && stripePK && getStripePromiseInstance(stripePK);

    const init = async (): Promise<void> => {
        if (active && !initialising && !state.paymentObject?.customerIntent) {
            initialising = true;

            const verifyUser: TUser | null = await userGetUser();

            if (!verifyUser || !verifyUser?.id) {
                initialising = false;
                console.error('Trying to initPayment but not yet a user');
                return;
            }

            initPayment()
                .then(validateResponse)
                .then((payment) => {
                    if (!unmounted) {
                        dispatch({
                            type: EBookingFlowAction.SetStripeCustomerIntent,
                            payload: payment.secret
                        });
                    }

                    if (interval) {
                        clearInterval(interval);
                    }
                })
                .catch((e) => console.error(e))
                .finally(() => {
                    initialising = false;
                });
        } else if (state.paymentObject?.customerIntent && interval) {
            clearInterval(interval);
        }
    };

    useEffect(() => {
        if (active) {
            init();
            setInterval(init, intervalTimeout);
        } else if (interval) {
            clearInterval(interval);
        }
    }, [active]);

    useEffect(() => {
        unmounted = false;

        return (): void => {
            unmounted = true;
        };
    }, []);

    return (
        <OxContainer>
            <Styled.DetailsHeading>Your Payment Details</Styled.DetailsHeading>

            {stripePK && !!stripePromise && (
                <Elements key={stripePK} stripe={stripePromise}>
                    <OxBookingSimplePaymentForm active={active} />
                </Elements>
            )}
            <OxBackButton onClick={onBack} />
        </OxContainer>
    );
};
