import styled, { css } from 'styled-components';
import { OxSelect } from 'src/components/OxSelect';
import { fluidSizing } from 'src/utils';
import { transparentize } from 'polished';
import { EFonts, EFontWeight } from 'src/config/enums';
import { createTextStyle } from 'src/utils/createTextStyle';
import { OxButton } from 'src/components/OxButton';

const spacing = [20, 20, 20];

export const Container = styled.div(
    ({ theme }) => css`
        background-color: ${transparentize(0.5, theme.colors.basic.white)};

        ${fluidSizing([
            { prop: 'margin-bottom', values: spacing.map((num) => num / 2) },
            { prop: 'padding-top', values: spacing },
            { prop: 'padding-bottom', values: spacing },
            { prop: 'padding-left', values: spacing.map((num) => num * 2) },
            { prop: 'padding-right', values: spacing.map((num) => num * 2) }
        ])}
    `
);

export const BillingSelect = styled(OxSelect)`
    ${fluidSizing([{ prop: 'margin-bottom', values: spacing }])}
`;

export const CardWrapper = styled.div(
    ({ theme }) => css`
        align-items: center;
        border: 1px solid ${theme.colors.primary.main};
        display: flex;
        justify-content: center;

        ${fluidSizing([
            { prop: 'height', values: [30, 30, 30] },
            { prop: 'border-radius', values: [15, 15, 15] },
            { prop: 'padding-left', values: [15, 15, 15] },
            { prop: 'padding-right', values: [15, 15, 15] }
        ])}

        > * {
            flex: 1;
        }
    `
);

export const Cms = styled.div(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Normal, [9, 9, 9], [11, 11, 11])}
        ${fluidSizing([{ prop: 'margin-top', values: spacing }])};

        > br {
            ${fluidSizing([{ prop: 'margin-top', values: spacing }])};
        }

        > a {
            display: inline;
            text-decoration: underline;
        }
    `
);

export const Note = styled.div(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Bold, [10, 10, 10], [14, 14, 14])}
        ${fluidSizing([{ prop: 'margin-bottom', values: spacing }])};

        > br {
            ${fluidSizing([{ prop: 'margin-bottom', values: spacing }])};
        }

        > a {
            display: inline;
            text-decoration: underline;
        }
    `
);

export const Button = styled(OxButton)`
    width: 100%;

    ${fluidSizing([{ prop: 'margin-top', values: spacing }])};
`;
