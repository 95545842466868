export const submitForm = (
    form: HTMLFormElement,
    additionalData: Record<string, any> = {}
): void => {
    if (!form) return;

    if (form.requestSubmit) {
        form.detail = additionalData;
        form.requestSubmit();
    } else {
        // https://stackoverflow.com/questions/37901223/react-js-submit-form-programmatically-does-not-trigger-onsubmit-event
        form.reportValidity &&
            form.reportValidity() &&
            form.dispatchEvent(
                new CustomEvent('submit', {
                    cancelable: true,
                    bubbles: true,
                    detail: additionalData
                })
            );
    }
};
