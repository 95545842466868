import styled, { css } from 'styled-components';

type TProps = {
    isActive: boolean;
    isNext: boolean;
    horizontal?: boolean;
};

export const OxBookingAnimation = styled.div<TProps>(
    ({ isActive, isNext, horizontal }) => css`
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        position: absolute;
        visibility: hidden;
        transition-duration: 800ms;
        transition-property: transform, opacity;
        transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
        ${horizontal &&
        css`
            transform: translateX(100%);
        `}
        ${!horizontal &&
        css`
            transform: translateY(100%);
        `}

    ${!isNext &&
        !isActive &&
        css`
            ${horizontal &&
            css`
                transform: translateX(-100%);
            `}
            ${!horizontal &&
            css`
                transform: translateY(-100%);
            `}
        `}
    ${isActive &&
        css`
            transform: translate(0, 0);
            opacity: 1;
            visibility: visible;
        `}
    `
);
