import * as Styled from './OxBookingSimpleClinicSelector.styled';

import React, { useContext } from 'react';
import { WebsiteDataContext } from 'src/context/WebsiteDataContext';
import { BookingFlowContext } from 'src/context/BookingFlowContext';
import { EBookingFlowAction } from 'src/components/OxBooking/OxBookingFlowReducer';
import { validateAppointment } from 'src/services/api';
import { useValidateResponse } from 'src/hooks/useValidateResponse';

type TLocation = {
    name: string;
    value: string;
    countryCode?: string;
};

type TProps = {
    name: string;
    appointmentTypeId: string;
    addTopPadding?: boolean;
};

export const OxBookingSimpleClinicSelector = ({
    name,
    appointmentTypeId,
    addTopPadding
}: TProps): JSX.Element => {
    const { validateResponse } = useValidateResponse();
    const websiteDataContext = useContext(WebsiteDataContext);
    const { state, dispatch } = useContext(BookingFlowContext);

    const clinicLocations = websiteDataContext.locations.map((location) => ({
        name: `${location.displayName} Clinic`,
        value: `${location.id}`,
        countryCode: location.countryCode
    }));

    const userClinic = clinicLocations.find((clinic) => clinic.value === `${state.user?.clinicId}`);

    const getClinicFromCountryCode = (countryCode: string): TLocation | undefined =>
        clinicLocations.find((item) => item.countryCode === countryCode);

    const initialValue: string | undefined =
        userClinic?.value ?? state.clinic?.id
            ? `${state.clinic?.id}`
            : getClinicFromCountryCode(websiteDataContext.geoIpCountry)?.value ??
              getClinicFromCountryCode('GB')?.value;

    const updateClinic = async (value: string): Promise<void> => {
        const clinic = websiteDataContext.locations.find((location) => `${location.id}` === value);
        if (state.consultationTime) {
            let removeConsultationTime = true;

            if (clinic?.id && appointmentTypeId) {
                dispatch({
                    type: EBookingFlowAction.SetProcessing,
                    payload: true
                });

                if (state.shouldValidateAppointment) {
                    const validAppointment = await validateAppointment({
                        clinicId: clinic.id,
                        appointmentTypeId: appointmentTypeId,
                        startsAt: state.consultationTime?.toUTC().toISO()
                    });

                    try {
                        validateResponse(validAppointment);

                        removeConsultationTime = !validAppointment?.available;
                    } catch (e) {
                        console.error(e);
                    }
                }
                // else {
                //   removeConsultationTime = false;
                // }

                dispatch({
                    type: EBookingFlowAction.SetProcessing,
                    payload: false
                });
            }

            if (removeConsultationTime) {
                dispatch({
                    type: EBookingFlowAction.SetCurrentStage,
                    payload: 0
                });

                dispatch({
                    type: EBookingFlowAction.SetConsultationDate,
                    payload: undefined
                });

                dispatch({
                    type: EBookingFlowAction.SetConsultationTime,
                    payload: undefined
                });
            }
        }

        dispatch({
            type: EBookingFlowAction.SetClinic,
            payload: clinic
        });
    };

    return (
        <Styled.RadioList
            name={name}
            items={clinicLocations}
            initialValue={initialValue}
            onValueChange={updateClinic}
            addTopPadding={addTopPadding}
            radioShape="circle"
        />
    );
};
