import { EFontWeight, EFonts, ESizes } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxForm } from 'src/components/OxForm';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';

export const Container = styled.div`
    position: relative;
`;

export const SuccessWrapper = styled.div(
    ({ theme }) => css`
        background-color: ${theme.colors.basic.ouronyxLogo};
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 10;
    `
);

export const Label = styled.span(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
        text-transform: uppercase;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [12, 12, 12], [16, 16, 16])}
    `
);

export const Title = styled.div(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Bold, [10, 10, 10], [18, 18, 18])}
        text-transform: uppercase;
        ${fluidSizing([
            { prop: 'margin-bottom', values: [10, 10, 10] },
            { prop: 'margin-top', values: [20, 20, 20] }
        ])}
    `
);

export const Heading = styled.h2`
    ${createTextStyle(EFonts.Hatton, EFontWeight.SemiBold, [14, 21, 24], [22, 32, 38])}
    ${fluidSizing([{ prop: 'margin-bottom', values: [30, 20, 20] }])}
`;

export const CallbackWrapper = styled(OxForm)<{ formSubmitted: boolean }>(
    ({ theme, formSubmitted }) => css`
        background-color: ${theme.colors.basic.ouronyxLogo};
        & > * {
            height: 100%;
        }
        ${fluidSizing([
            { prop: 'padding-bottom', values: [35, 60, 60] },
            { prop: 'padding-top', values: [20, 60, 60] },
            { prop: 'height', values: [870, 600, 600] }
        ])}
        ${formSubmitted &&
        css`
            ${fluidSizing([{ prop: 'height', values: [450, 760, 600] }])}
        `}
    `
);

export const SemiBold = styled.b(
    ({ theme }) => css`
        font-weight: ${theme.fontWeights.semiBold};
    `
);

export const CallbackContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        height: 100%;
        & > * {
            &:first-child {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                ${fluidSizing([
                    {
                        prop: 'padding-left',
                        values: [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM]
                    },
                    {
                        prop: 'padding-right',
                        values: [ESizes.GutterXS, null, null]
                    },
                    { prop: 'margin-bottom', values: [20, null, null] }
                ])}
                ${theme.breakpoints.only('xs')} {
                    flex: 1;
                }
            }
            &:last-child {
                ${fluidSizing([
                    {
                        prop: 'padding-left',
                        values: [ESizes.GutterXS, null, null]
                    },
                    {
                        prop: 'padding-right',
                        values: [ESizes.GutterXS, ESizes.GutterS, ESizes.GutterM]
                    },
                    { prop: 'margin-bottom', values: [10, null, null] }
                ])}
            }
        }
        ${theme.breakpoints.only('xs')} {
            flex-direction: column;
        }
        ${theme.breakpoints.up('s')} {
            & > *:nth-child(1) {
                flex: 1;
                height: 100%;
            }
            & > *:nth-child(2) {
                flex: 1;
                height: 100%;
            }
        }
    `
);
